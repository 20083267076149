@import "src/Styles/settings/variables";

.button-loader-container {
  position: relative;
  margin-top: 5px;

  &.loading {
    span {
      visibility: hidden;
    }
  }

  .request-loading {
    background-color: unset;
    .loader {
      padding: 0;
    }
  }

  .button-loader {
    background-color: $lighter-color;
  }
}