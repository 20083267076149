@import "src/Styles/settings/variables";

.legends {
  display: flex;
  flex-wrap: wrap;
  margin-top: $margin;
  cursor: default;

  .statusIcon {
    margin-right: $margin;
  }
}

.status-tooltip {
  box-shadow: $shadow;
  opacity: 1 !important;

  &:after {
    box-sizing: border-box;
    border: 8px solid transparent;
    border-color: transparent transparent $lighter-color $lighter-color !important;
    box-shadow: -3px 3px 3px -1px rgba(0, 0, 0, 0.2);
    transform: rotate(-45deg);
  }
}