@import "src/Styles/settings/variables.scss";
@import "src/Styles/mixins/mixins.scss";

$tablet-wide-menu-height: calc(#{$menu-horizontal-height} + #{$menu-submenu-horizontal-height});

.panel{
  .panel-container{
    margin-top: $menu-horizontal-height;
    min-height: calc(100vh - #{$menu-horizontal-height});
    padding: $bigMargin $padding;

    @include mq(tablet-wide) {
      min-height: calc(100vh - #{$tablet-wide-menu-height});
      margin-top: $tablet-wide-menu-height;
      padding-top: $bigPadding;

      &.small {
        margin-top: $menu-horizontal-height;
      }

      &.require-email-confirm {
        padding-top: 60px;
      }
    }
  }

  .warning-banner {
    width: 100%;
    padding: 5px 0;
    background-color: $warning-color;
    color: #000;
    font-weight: bold;
    position: fixed;
    top: 0;
    z-index: 10000;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  @keyframes marquee {
    0% {
      transform: translate(200%, 0)
    }
    100% {
      transform: translate(-200%, 0)
    }
  }

}

