@import "src/Styles/settings/variables.scss";

.money-label {
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;

  svg {
    margin-left: $spaceMargin;
  }
  
  &.negative {
    .symbol, .amount {
      color: $error-color;
    }
  }

  &.positive {
    .symbol, .amount {
      color: $success-color;
    }
  }

  .symbol {
    font-size: $regular;
    margin-right: 2px;
    font-weight: normal;
    margin-top: 4px;
  }

  .amount {
    font-size: $bigger;
    font-weight: bold;
  }
}