@import "src/Styles/settings/variables.scss";

.box-panel {
  .box-content {
    position: relative;
    .card{
      background: $lighter-color;
      padding: $bigPadding;
      border: 1px solid $light-color;
      border-radius: $box-radius;
      position: relative;
    }
  }
}
