@import "src/Styles/settings/variables";

.request-loading {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($lighter-color, 0.8);

  .loader {
    align-items: center;
    background-color: transparent;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    padding: 0 18px;
    position: absolute;
    top: 0;
    width: 100%;

    .colored {
      background-color: $gray-color;
    }

    &:hover {
      cursor: wait;
    }

    div {
      animation: showHideDot 1s ease-in-out infinite;
      border-radius: 50%;
      display: inline-block;
      height: 8px;
      margin: 0 3px;
      opacity: 0;
      position: relative;
      width: 8px;

      &:first-child {
        animation-delay: 0.2s;
      }
      &:nth-child(2) {
        animation-delay: 0.4s;
      }
      &:last-child {
        animation-delay: 0.8s;
      }
    }
  }
}

@keyframes showHideDot {
  0% {
    opacity: 0
  }

  50% {
    opacity: 1
  }

  60% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}
