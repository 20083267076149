@import 'src/Styles/mixins/mixins.scss';
@import 'src/Styles/settings/variables';

// TERMS AND CONDITIONS

.terms-page-wrapper {
    display: flex;
}

.terms-page-main {
    width: 100%;

    @include mq(phablet) {
        .common-box {
            max-height: calc(100vh - 200px);
            overflow-y: scroll;
        }
    }
}

.terms-page-sidebar {

    .btn {
        color: #fff;
        min-width: 140px;
    }

    @include mq(phablet, max) {
        position: fixed;
        background: #fff;
        bottom: 0;
        width: 100%;
        left: 0;
        padding: 20px;
        box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-flow: row-reverse;
        justify-content: space-between;
    }

    @include mq(phablet) {
        max-width: 140px;
        margin-left: 50px;
        text-align: right;

        .btn {
            margin-bottom: 30px;
        }
    }
}

canvas.react-pdf__Page__canvas {
    width: 100%!important;
    height: auto!important;
}

.react-pdf__Page__annotations.annotationLayer {
    display: none;
}

// POLITICA DE COOKIES
.modal-cookies_politic-title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.0863639px;
}

.container_cookies_politic {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 65vh;
    padding: 20px 32px;
    margin-bottom: 1.3rem;
    border: 1px solid rgba(85, 85, 85, 0.3);
    overflow-y: auto;

    & > h3 {
        margin-top: 1.5rem;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 19px;
        letter-spacing: -0.0863639px;
        text-transform: uppercase;
    }

    & > h4 {
        margin-top: 1rem;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: -0.0863639px;
        color: #555555;
        text-transform: uppercase;
    }

    & > p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #555555;
        margin-bottom: 1rem;
    }

    & a:hover {
        text-decoration: none;
    }

    & > .link-topic {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;
        color: #555555;
        cursor: pointer;
        width: fit-content;

        &:hover {
            text-decoration: none;
            font-weight: bold;
        }

        &:visited {
            color: #555555;
        }
    }
}

.container-cookies_politic-btn {
    display: flex;
    justify-content: flex-end;
}

// informacao de cookies
.container-cookies_use {
    position: absolute;
    width: 100%;
    bottom: 2.6rem;
    display: flex;
    align-content: center;
    justify-content: center;

    & > #modal {
        width: 80%;
        padding: 2rem 3.4rem;
        background: $lighter-color;
        box-shadow: 0px 8px 12px $darker-color--alpha-02;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > p > strong {
            cursor: pointer;
        }

        & > span {
            height: 2rem;
            width: 3rem;
            padding-right: 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }

    @include mq(phone-wide, 'max') {
        position: fixed;
        bottom: 0;

        & > #modal {
            width: 100%;
            padding: .5rem;
            border-radius: 0;
            align-items: end;
        }
    }
}

.cookies_use-close-btn {
    font-weight: bold;
    cursor: pointer;

    svg {
        width: $mediumIconSize;
        height: $mediumIconSize;
    }
}
