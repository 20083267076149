@import "src/Styles/settings/variables.scss";

.box-header {
  background: transparent;
  margin-bottom: $smallMargin;

  hr {
    border: 0 none;
    border-bottom: 1px solid $primary-color;
    margin: 0;
  }

  h5 {
    margin: 0;
    margin-bottom: $smallMargin;
    font-family: $font-family;
    font-weight: bold;
  }
}
