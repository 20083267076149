@import 'settings/variables';
@import 'mixins/mixins';
@import '~bootstrap/scss/bootstrap.scss';
@import 'generic/font-face';
@import 'generic/reset';

#root {
  min-height: 100vh;
 
  > .loader-wrapper {
    width: 100vw;
    height: 100vh;
  }
}


