@import "src/Styles/settings/variables.scss";

.resume-field {

  &-title {
    color: $dark-color;
    font-size: $regular;
  }

  &-value {
    font-size: $bigger;
    font-weight: bold;
    margin: 0;
    
    svg {
      cursor: pointer;
    }
  }
}
