.customModalMFA {
  max-width: 400px;
}

.mfa {
  .retry-container {
    padding: 10px 0;

    .btn-retry {
      padding: 0 5px;
    }
  }

  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      width: 100%;

      &:first-child {
        margin-right: 10px;
      }
    }
  }
}