.pinpad {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .error-message {
    color: #FC3D3D;
    margin-top: 2px;
    font-size: 12px;
  }

  .wrapper {
    margin-bottom: 0;
    padding: 0;
  }

  .pin-input {
    text-align: center;
    width: 35px;
    height: 35px;
    font-size: 16px;
    border-radius: 3px;
    margin: 0 2px;
    outline: none;

    &.primary {
      color: '#555';
      border: 1px solid #B8B8B8;

      &.filled {
        border-color: #4E4E4E;
      }

      &:focus {
        border-color: #4E4E4E;
      }
    }

    &.danger {
      color: '#FC3D3D';
      border: 1px solid #FC3D3D;
    }
  }
}

// const ErrorLabel = styled.span`
//   font-size: 12px;
//   color: #FC3D3D;
// `
