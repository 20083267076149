@import "src/Styles/settings/variables";
@import "animations";

html, body {
  margin: 0;
  padding: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  font-size: $regular;
  color: $dark-color;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

h1, h2, h3, h4, h5, h6, b, i, p {
  font-family: $font-family;
}

h1{
  font-size: $bigger;
  font-weight: bold;
  margin-bottom: $margin;
  color: $dark-color;
}

h2{
  font-size: $big;
  font-weight: bold;
}

hr {
  margin: 0;
}

small {
  font-size: $small;
}

.top-navigation{
  padding-bottom: $smallPadding;

  .navigation-wrapper {
    display: inline-block;
    cursor: pointer;
  }
}

.btn-link {
  font-weight: bold;

  &:hover, &:focus {
    text-decoration: none;
  }
}

.whitelabel-background-color-light {
  background-color: $light-color;
}

.rc-menu {
  border: none;
  box-shadow: none;
}

.Collapsible {
  width: 100%;
  margin-bottom: 0;
  box-shadow: $card-box-shadow;
  -webkit-border-radius: $box-radius;
  -moz-border-radius: $box-radius;
  border-radius: $box-radius;
}

.Collapsible__contentInner {
  border: none;
  border-radius: 0 0 $box-radius $box-radius;
  background-color: $lighter-color;
}

.Collapsible__trigger {
  padding: $padding;
  border: none;
  display: block;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  background: $lighter-color;
  border-radius: $box-radius;
  color: $darker-color;
  transition: border-bottom-color 100ms linear;

  .icon {
    position: absolute;
    font-size: $bigger;
    top: 20%;
    right: 2%;
  }

  .icon-opened {
    display: none;
  }

  &:after {
    display: none;
  }

  &.is-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &:after {
      transform: rotateZ(90deg);
    }

    &.active {
      border-bottom: none;
    }

    .icon-closed {
      display: none;
    }

    .icon-opened {
      display: inline;
    }
  }

  &.is-closed {
    border-bottom-left-radius: $box-radius;
    border-bottom-right-radius: $box-radius;

    &.active {
      border-radius: $box-radius;
    }
  }

  &.is-display {
    opacity: 0.5;
    background-color: grey;
  }
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled, .btn-outline-primary:disabled:hover {
  background-color: transparent;
}

.btn-outline-secondary {
  color: $dark-color;
  border-color: $darker-color--alpha-03;
  font-weight: bold;
}
.custom-toast {
  border-radius: 4px;
  padding: $padding;
  display: flex;
  align-items: center;
  min-height: unset;

  .toast-container {
    display: flex;
    .message {
      margin-left: $smallMargin;
    }

    .icon {
      margin-top: 4px;
    }
  }

  .Toastify__toast--default {
    background: $light-color;
    color: $lighter-color;
  }
  .Toastify__toast--success {
    background: $success-color;
  }
  .Toastify__toast--warning {
    background: $warning-color;
  }
  .Toastify__toast--error {
    background: $error-color;
  }
}

.dropdown-toggle::after {
  margin-left: 0.260em;
  vertical-align: 0.2em;
  display: inline-block;
  width: 6px;
  height: 6px;
  transform: rotate(45deg);
  content: "";
  border-right: 2px solid;
  border-bottom: 2px solid;
  border-top: none;
  border-left: none;
  border-radius: 2px;
}

.react-sweet-progress-symbol {
  width: auto !important;
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
